import React, { useEffect, useRef, useState } from "react";
import {
    AppBar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import axios from "../api/axios";
import PrimaryButton from "../components/PrimaryButton";
import { loadScript } from "../utils/razorpay";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';

const OrderPayment = () => {
    const outlet = localStorage.getItem("outlet");
    const kioskUser = JSON.parse(localStorage.getItem("kioskUser"));
    const cartId = localStorage.getItem("cartId");
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const idleTimerRef = useRef(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [additionalCharges, setAdditionalCharges] = useState({});
    const [userDetails, setUserDetails] = useState(null);
    const [makingTime, setMakingTime] = useState("");
    const [makingTimeInMinutes, setMakingTimeInMinutes] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [qtyChangingIds, setQtyChangingIds] = useState([]);
    const [deletingIds, setDeletingIds] = useState([]);
    const [fetchTime, setFetchTime] = useState(false);

    const handleQuantityChange = async (productId, newQuantity) => {
        setQtyChangingIds((prevIds) => [...prevIds, productId]);

        // Backup previous cart items for rollback in case of error
        const previousCartItems = [...cartItems];

        // If the quantity is zero, remove the item locally before making the server request
        if (newQuantity === 0) {
            setCartItems((prevItems) => prevItems.filter((item) => item._id !== productId));
        } else {
            // Otherwise, update the quantity in the local state
            setCartItems((prevItems) =>
                prevItems.map((item) =>
                    item._id === productId ? { ...item, quantity: newQuantity } : item
                )
            );
        }

        try {
            if (newQuantity === 0) {
                // If quantity is zero, delete the item from the server
                deleteCartItem(productId);
                return;
            }

            const token = localStorage.getItem("authToken");
            const data = {
                cartItems: [{ _id: productId, quantity: newQuantity }],
            };

            // Send update request to the server
            const response = await axios.put("cart", data, {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Reload the cart items from the server only if needed
            if (response?.status === 200) {
                getCartItems();
            }
        } catch (error) {
            // Rollback on error
            setCartItems(previousCartItems);

            let errorMessage = '';
            if (error.response?.status === 406) {
                errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
            } else if (error.response?.status === 412) {
                errorMessage = error?.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error;
            }

            toast.error(errorMessage, {
                position: "bottom-center",
                autoClose: 3000,
                theme: "colored",
            });
        } finally {
            setQtyChangingIds((prevIds) => prevIds.filter((id) => id !== productId));
        }
    };

    const getCartItems = async () => {
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.get(`cart/userCart/${outlet}/PICKUP`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.data?.status === true) {
                const totalAmount = response?.data?.data?.cart?.grandTotal;
                setAdditionalCharges(response?.data?.data?.additionalCharges);
                setTotalAmount(totalAmount);
                setCartItems(response?.data?.data?.cartItems);
                localStorage.setItem(
                    "cartItems",
                    JSON.stringify(response?.data?.data?.cartItems)
                );
                localStorage.setItem(
                    "cart",
                    JSON.stringify(response?.data?.data?.cart)
                );
                localStorage.setItem(
                    "additionalCharges",
                    JSON.stringify(response?.data?.data?.additionalCharges)
                );
                getCartReadyTime()
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const createOrder = async () => {
        setIsLoading(true)
        try {
            const token = localStorage.getItem("authToken");

            const payload = {
                ordersType: "PICKUP",
                paymentMode: "ONLINE",
                outlet: outlet,
                readyTime: makingTime,
            };

            const response = await axios.post("order/create", payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.status === 201) {
                localStorage.setItem("orderId", response?.data?.data?.order?.id);
                localStorage.setItem(
                    "orderAmount",
                    response?.data?.data?.order?.amount
                );
                // navigateToOrderPayment();
                handlePayment()
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false)
        }
    };

    const handlePayment = async () => {
        try {
            setBtnLoading(true);

            const order_id = localStorage.getItem("orderId");
            const amount = totalAmount;
            const userPhone = kioskUser?.phone || '';

            if (!order_id || !amount) {
                throw new Error('Order ID or amount is missing');
            }

            const options = {
                key: process.env.RPAY_KEY,
                amount: amount,
                currency: "INR",
                order_id: order_id,
                handler: function (response) {
                    handlePaymentVerification(response);
                },
                prefill: {
                    name: "Xpanse",
                    contact: userPhone,
                },
                notes: {
                    address: "123, Street Name, City, Country",
                },
                theme: {
                    // color: orderData?.configuration?.theme?.color,
                },
                modal: {
                    ondismiss: function () {
                        console.log("Payment modal closed.");
                    }
                }
            };

            localStorage.removeItem("cart_id");

            // Initialize Razorpay and open the payment modal
            const razorpay = new window.Razorpay(options);
            // razorpayRef.current = razorpay;
            // setRazorpayInstance(razorpay);
            // resetIdleTimer();
            razorpay.open();
        } catch (error) {
            console.error("Payment initiation failed:", error);
            alert("Payment initiation failed. Please try again.");
        } finally {
            setBtnLoading(false);
        }
    };

    const handlePaymentVerification = async (razorpay) => {
        setIsLoading(true)
        try {
            const authToken = localStorage.getItem("authToken");
            const kioskUser = JSON.parse(localStorage.getItem("kioskUser"));

            if (!authToken) {
                throw new Error("Authentication token is missing.");
            }

            const headers = {
                Authorization: `Bearer ${authToken}`,
            };

            const payload = {
                razorpayOrderId: razorpay?.razorpay_order_id,
                razorpayPaymentId: razorpay?.razorpay_payment_id,
                razorpaySignature: razorpay?.razorpay_signature,
                paymentMode: "ONLINE",
                outlet: outlet,
                readyTime: makingTime,
                arrivalTimeDurationInMinutes: makingTimeInMinutes,
                kioskUser: kioskUser?._id,
            };

            const response = await axios.post("order/verifyPayment", payload, {
                headers,
            });
            if (response?.status === 201) {
                const orderNum = response?.data?.data?.order?.order_no;
                const orderSequence = response?.data?.data?.order?.order_sequence;

                if (orderNum && orderSequence) {
                    const order = `#${orderSequence}${orderNum}`;
                    localStorage.setItem('orderNumber', order)
                } else {
                    throw new Error("Order number or sequence is missing.");
                }
                navigate("/order-confirmation");
            }
        } catch (error) {
            if (error.response && error.response.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            } else {
                toast.error("An unexpected error occurred.", {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false)
        }
    };

    const deleteCartItem = async (id) => {
        setDeletingIds((prevIds) => [...prevIds, id]);
        try {
            const token = localStorage.getItem("authToken");
            const payload = { cartItemIds: [id] };

            const response = await axios.delete("cart", {
                headers: { Authorization: `Bearer ${token}` },
                data: payload,
            });

            if (response?.status === 200) {
                const message = response?.data?.data?.message;
                toast.success(message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });

                getCartReadyTime()
            }
        } catch (error) {
            let errorMessage = '';
            if (error.response?.status === 406) {
                errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
            } else if (error.response?.status === 412) {
                errorMessage = error?.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error;
            }

            toast.error(errorMessage, {
                position: "bottom-center",
                autoClose: 3000,
                theme: "colored",
            });
        } finally {
            setDeletingIds((prevIds) => prevIds.filter((itemId) => itemId !== id));
        }
    };

    const navigateToProductPage = () => {
        navigate('/products')
    }

    const getCartReadyTime = async () => {
        setFetchTime(true)
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.get(`cart/getMakingTIme`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.data?.status === true) {
                console.log("Called");

                const readyTime = response?.data?.data?.readyTime
                localStorage.setItem('cartReadyTime', readyTime)
                setMakingTime(response?.data?.data?.readyTime);
                const time = response?.data?.data?.makingTimeDuration.split(" ")[0]
                setMakingTimeInMinutes(time)
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setFetchTime(false)
        }
    };

    const navigateToHome = () => {
        navigate('/user-details')
    }

    const handleLogout = () => {
        localStorage.removeItem('cart')
        localStorage.removeItem("cartItems");
        localStorage.removeItem("cartId");
        localStorage.removeItem("kioskUser");
        localStorage.removeItem("orderAmount");
        localStorage.removeItem("orderId");
        localStorage.removeItem("additionalCharges");
        localStorage.removeItem("cartReadyTime");
        localStorage.removeItem("rzp_checkout_anon_id")
        localStorage.removeItem("rzp_stored_checkout_id")
        localStorage.removeItem("rzp_device_id")
        if (cartId) deleteCart(cartId);
        navigateToHome()
    }

    const deleteCart = async (id) => {
        try {
            const token = localStorage.getItem("authToken");

            const payload = {
                cartId: id,
            };

            console.log("payload", payload);


            const response = await axios.delete("cart", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: payload,
            });

            if (response?.status === 200) {
                const message = response?.data?.data?.message;
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // const resetIdleTimer = () => {
    //     if (idleTimerRef.current) clearTimeout(idleTimerRef.current);

    //     idleTimerRef.current = setTimeout(() => {    

    //         handleLogout();

    //         // Refresh the page
    //         window.location.reload();
    //     }, 1800000); // 1 minute
    // };

    useEffect(() => {
        const kioskUser = JSON.parse(localStorage.getItem("kioskUser"));
        setUserDetails(kioskUser);
        getCartItems();
    }, []);

    useEffect(() => {
        const loadRazorpayScript = async () => {
            try {
                await loadScript("https://checkout.razorpay.com/v1/checkout.js");
                console.log("Razorpay script loaded.");
            } catch (error) {
                console.error("Failed to load Razorpay script:", error);
            }
        };
        loadRazorpayScript();
    }, []);

    // useEffect(() => {
    //     const handleUserActivity = () => resetIdleTimer();
    //     window.addEventListener("mousemove", handleUserActivity);
    //     window.addEventListener("keydown", handleUserActivity);
    //     window.addEventListener("scroll", handleUserActivity);
    //     window.addEventListener("touchstart", handleUserActivity);

    //     return () => {
    //         window.removeEventListener("mousemove", handleUserActivity);
    //         window.removeEventListener("keydown", handleUserActivity);
    //         window.addEventListener("scroll", handleUserActivity);
    //         window.addEventListener("touchstart", handleUserActivity);
    //     };
    // }, []);

    if (isLoading)
        return <Box sx={{
            width: '500px',
            height: '100vh',
            backgroundColor: '#f0f4f8',
            margin: 'auto',
            marginTop: '300px'
        }}>
            <CircularProgress sx={{
                color: '#1976d2',
                size: 80,
                thickness: 5
            }} />
        </Box>
    return (
        <>
            <AppBar position="fixed" color="inherit" elevation={0} >
                <Toolbar sx={{ padding: '10px', background: '#dfeef5' }}>
                    {/* Brand Logo */}
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <img src="/assets/brand-logo.png" alt="Brand Logo" style={{ height: 50, marginRight: 5 }} />
                    </Box>

                    {/* Logout Button */}
                    <Box sx={{ flexGrow: 0 }}>
                        <Button
                            variant="contained"
                            onClick={handleLogout}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '8px 12px',
                                backgroundColor: '#fff',
                                color: '#000',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}
                        >
                            <LogoutIcon sx={{ mb: 0.5, color: '#000' }} />
                            Close
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>

            <Box sx={{ height: 'calc(100vh - 100px)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', background: '#dfeef5', paddingTop: '90px' }}>
                {/* Arrow Box */}
                <Box sx={{ width: '52%', bgcolor: 'white', boxSizing: 'border-box', borderTopRightRadius: '50px', overflow: 'hidden' }} p={2} >
                    <Box sx={{ bgcolor: '#dfeef5', borderTopRightRadius: '10px' }} mb={1}>
                        <Typography variant="h6" fontSize={25} color={'#0E4961'}>Your Cart</Typography>
                    </Box>
                    {/* Cart Items Box */}

                    {
                        cartItems && cartItems.length > 0 && (
                            <Box pl={1}>
                                <Typography textAlign={'left'} variant="h5" fontWeight={'bold'} color={'#0E4961'} fontSize={20}>
                                    {userDetails?.name || "User Name"}
                                </Typography>
                                <Typography
                                    fontWeight={"default"}
                                    textAlign={"left"}
                                    variant="body1"
                                    fontSize={14}
                                >
                                    {userDetails?.email}
                                </Typography>
                                <Typography
                                    fontWeight={"bold"}
                                    textAlign={"left"}
                                    variant="body1"
                                    fontSize={16}
                                >
                                    {userDetails?.phone || "User Phone"}
                                </Typography>
                            </Box>
                        )
                    }
                    <Box
                        sx={{
                            width: "100%",
                            overflowY: "auto",
                            height: '80%',
                            marginRight: '2%',
                        }}
                    >
                        {cartItems && cartItems.length > 0 ? (
                            <>

                                <Box pl={1}>
                                    <Stack direction={'column'} mt={2}>
                                        {
                                            cartItems?.length > 0 && cartItems?.map((item, index) => {
                                                const isFirst = index === 0;
                                                const isLast = index === cartItems.length - 1;
                                                return (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            position: 'relative',
                                                            borderTopLeftRadius: isFirst ? '20px' : '0',
                                                            borderBottom: (!isFirst && !isLast) || (cartItems?.length === 2) ? '2px dashed #e0e0e0' : 'none',
                                                            borderTop: !isFirst && !isLast ? '2px dashed #e0e0e0' : 'none',
                                                            borderTopRightRadius: isFirst ? '20px' : '0',
                                                            bgcolor: '#dfeef5',
                                                            minHeight: '70px',
                                                            borderBottomLeftRadius: isLast ? '20px' : '0',
                                                            borderBottomRightRadius: isLast ? '20px' : '0',
                                                            paddingTop: isFirst ? '10px' : '8px'
                                                        }}
                                                        p={2}
                                                    >
                                                        {item?.product?.isDelete && (
                                                            <Box
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    bgcolor: 'rgba(0, 0, 0, 0.3)',
                                                                    zIndex: 1,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    borderRadius: 'inherit',
                                                                    color: 'red',
                                                                    fontWeight: 'bold',
                                                                    fontSize: '1.2rem'
                                                                }}
                                                            >
                                                                OUT OF STOCK
                                                            </Box>
                                                        )}
                                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                                            <Box sx={{ width: '45%' }}>
                                                                <Typography fontWeight={'bold'} textAlign={'left'} color={'#0E4961'}>
                                                                    {item?.product?.name}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ width: '20%', }}>
                                                                <Typography fontWeight={'bold'} textAlign={'right'} sx={{ color: '#00b0ff' }}>
                                                                    ₹{(
                                                                        (item?.rate || 0) * (item?.quantity || 1) +
                                                                        item?.addons?.reduce(
                                                                            (acc, item) => acc + (item?.offerPrice || 0),
                                                                            0
                                                                        ) * (item?.quantity || 1)
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Stack>
                                                        <Grid container>
                                                            <Typography
                                                                sx={{
                                                                    color: "#999",
                                                                    fontSize: "0.9rem",
                                                                    marginTop: "5px"
                                                                }}
                                                            >
                                                                {item?.addons?.map((addon) => addon?.selectedValue)?.join(', ')}
                                                            </Typography>
                                                        </Grid>
                                                        <Stack direction={'row'} justifyContent={"flex-end"}>
                                                            <Box sx={{ width: '30%' }}>
                                                                <Stack direction={"row"} alignItems={'center'} justifyContent={'space-around'}>
                                                                    <>
                                                                        {item?.quantity === 1 ? (
                                                                            <DeleteIcon
                                                                                onClick={() => {
                                                                                    if (!deletingIds.includes(item?._id)) {
                                                                                        handleQuantityChange(item?._id, item?.quantity - 1);
                                                                                    }
                                                                                }}
                                                                                className="cursor-p"
                                                                                style={{
                                                                                    fontSize: "1.2rem",
                                                                                    color: "#fff",
                                                                                    backgroundColor: "#fe7678",
                                                                                    borderRadius: "50%",
                                                                                    padding: "3px",
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <Remove
                                                                                onClick={() => {
                                                                                    if (!qtyChangingIds?.includes(item?._id)) {
                                                                                        handleQuantityChange(item?._id, item?.quantity - 1);
                                                                                    }
                                                                                }}
                                                                                className="cursor-p"
                                                                                style={{
                                                                                    fontSize: "1.2rem",
                                                                                    color: "#fff",
                                                                                    backgroundColor: "#00b0ff",
                                                                                    borderRadius: "50%",
                                                                                    padding: "3px",
                                                                                }}
                                                                            />

                                                                        )}

                                                                        <Stack
                                                                            direction={"row"}
                                                                            alignItems={"center"}
                                                                            justifyContent={"center"}
                                                                            sx={{
                                                                                width: "20px",
                                                                                textAlign: "center",
                                                                                fontWeight: 'bold',
                                                                                marginTop: '-1px'
                                                                            }}
                                                                        >
                                                                            {qtyChangingIds.includes(item?._id) ? (
                                                                                <CircularProgress size={16} color='success' />
                                                                            ) : deletingIds.includes(item?._id) ? (
                                                                                <CircularProgress size={16} color='error' />
                                                                            ) : (
                                                                                item?.quantity
                                                                            )}
                                                                        </Stack>
                                                                        <Add
                                                                            onClick={() => {
                                                                                if (!qtyChangingIds?.includes(item?._id)) {
                                                                                    handleQuantityChange(item?._id, item?.quantity + 1);
                                                                                }
                                                                            }}
                                                                            className="cursor-p"
                                                                            style={{
                                                                                fontSize: "1.2rem",
                                                                                color: "#fff",
                                                                                backgroundColor: "#00b0ff",
                                                                                borderRadius: "50%",
                                                                                padding: "3px",
                                                                            }}
                                                                        />
                                                                    </>
                                                                </Stack>
                                                            </Box>
                                                        </Stack>
                                                    </Box>
                                                )
                                            })}
                                    </Stack>
                                </Box>

                                <PrimaryButton title={'Add more items'}
                                    onClick={navigateToProductPage}
                                    // disabled={isLoading}
                                    // spinner={isLoading}
                                    // disabledTitle={'Add more items'}
                                    style={{
                                        borderRadius: "30px", fontSize: "18px",
                                        width: '100%',
                                        marginTop: '10px',
                                        marginBottom: '20px',
                                    }} />
                            </>
                        ) : <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                backgroundColor: "#f9f9f9",
                                overflow: "hidden",
                                width: "90%",
                                bgcolor: "white",
                                marginTop: '50px'
                            }}
                        >
                            <img
                                src="/assets/empty-cart.png"
                                width={"60%"}
                                style={{ marginBottom: "16px" }}
                            />
                            <Typography variant="h6" sx={{ color: "#555" }}>
                                Your cart is currently empty
                            </Typography>
                        </Box>}
                    </Box>
                </Box>

                {/* Payment Box */}
                <Box
                    sx={{
                        flex: "0 0 43%", background: '#dfeef5', marginRight: '2%'
                    }}
                >
                    <Typography variant='h5' mb={1} fontWeight={'bold'} textAlign={'left'} color={'#0E4961'}>Order Summary</Typography>
                    <Card sx={{ flex: 1, display: 'flex', flexDirection: 'column', borderRadius: '20px' }}>
                        <CardContent sx={{ flex: 1 }}>
                            <Grid container justifyContent="space-between" p={1}>
                                <Grid item sx={{ textAlign: "left" }}>
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">Item(s)</Typography>
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">Total MRP</Typography>
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">Total Sale Price</Typography>
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">Total Tax Price</Typography>
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">Platform Charges</Typography>
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">Discounts</Typography>
                                </Grid>
                                <Grid item sx={{ textAlign: "right" }}>
                                    {/* Total Quantity */}
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">
                                        {cartItems?.filter(item => item.quantity > 0).reduce((acc, item) => acc + item.quantity, 0)}
                                    </Typography>

                                    {/* Total MRP */}
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">
                                        ₹ {cartItems
                                            ?.filter(item => item.quantity > 0) // Filter items with quantity > 0
                                            .map((item) => {
                                                // Calculate the product's total MRP
                                                const productMrpTotal = Array.isArray(item.product)
                                                    ? item.product.reduce((accumulator, productItem) => accumulator + Number(productItem.mrp || 0), 0)
                                                    : (Number(item.product?.mrp) || 0);

                                                // Calculate the addons' total MRP if addons exist
                                                const addonsMrpTotal = item.addons
                                                    ? item.addons.reduce((accumulator, addon) => accumulator + Number(addon.mrp || 0), 0)
                                                    : 0;

                                                // Multiply by the quantity and return the combined total
                                                return (productMrpTotal + addonsMrpTotal) * item.quantity;
                                            })
                                            .reduce((acc, value) => acc + value, 0)
                                            .toFixed(2)}
                                    </Typography>

                                    {/* Total Sale Price */}
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">
                                        ₹ {cartItems
                                            ?.filter(item => item.quantity > 0) // Filter items with quantity > 0
                                            .reduce((acc, product) => acc + product.taxable, 0)
                                            .toFixed(2)}
                                    </Typography>

                                    {/* Total Tax Price */}
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">
                                        ₹ {cartItems
                                            ?.filter(item => item.quantity > 0) // Filter items with quantity > 0
                                            .reduce((acc, product) => acc + product.taxAmount, 0)
                                            .toFixed(2)}
                                    </Typography>

                                    {/* Platform Charges */}
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">
                                        ₹ {((additionalCharges?.platformFees || 0) + (additionalCharges?.GSTPlatformFees || 0)).toFixed(2)}
                                    </Typography>

                                    {/* Discounts */}
                                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">FREE!</Typography>
                                </Grid>
                            </Grid>

                            <Box sx={{ border: '1px dashed #d6d7db' }}></Box>
                            <Grid container justifyContent="space-between" mt={1}>
                                <Grid item>
                                    <Typography variant="h5" fontWeight={'medium'}>Grand Total</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" fontWeight={'medium'}>
                                        ₹ {(
                                            cartItems?.filter(item => item.quantity > 0).reduce((acc, product) => acc + (product.grandTotal || 0), 0) +
                                            (additionalCharges?.platformFees || 0) +
                                            (additionalCharges?.GSTPlatformFees || 0)
                                        ).toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" mt={2} bgcolor="#d82a29" sx={{ padding: "3px 5px" }} borderRadius="5px">
                                <Grid item>
                                    <Typography color="white" variant="h5" fontWeight="medium">You Saved</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color="white" variant="h5" fontWeight="medium">
                                        ₹ {(
                                            cartItems
                                                ?.filter(item => item.quantity > 0) // Filter items with quantity > 0
                                                .map((item) => {
                                                    // Calculate the total MRP for the product(s)
                                                    const productMrpTotal = Array.isArray(item.product)
                                                        ? item.product.reduce((accumulator, productItem) => accumulator + Number(productItem.mrp || 0), 0)
                                                        : (Number(item.product?.mrp) || 0);

                                                    // Calculate the total MRP for addons, if any
                                                    const addonsMrpTotal = item.addons
                                                        ? item.addons.reduce((accumulator, addon) => accumulator + Number(addon.mrp || 0), 0)
                                                        : 0;

                                                    // Total MRP for product and addons, multiplied by quantity
                                                    return (productMrpTotal + addonsMrpTotal) * item.quantity;
                                                })
                                                .reduce((acc, value) => acc + value, 0) // Sum up all items' MRP totals
                                            - cartItems
                                                ?.filter(item => item.quantity > 0) // Filter items with quantity > 0
                                                .reduce((acc, product) => acc + Number(product.taxable || 0), 0) // Sum of taxable amounts to subtract
                                        ).toFixed(2)}
                                    </Typography>

                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                    <Typography variant='h6' mb={1} mt={1} textAlign={'left'} color={'#0E4961'}>Estimated Order Arrival Time
                    </Typography>
                    <Typography variant='h5' fontSize={30} fontWeight={'bold'} mb={1} textAlign={'left'} color={'#0E4961'}>{cartItems.length > 0 ? makingTime : '00:00'}
                    </Typography>

                    <PrimaryButton
                        title={fetchTime ? "Please Wait..." : "PROCEED TO PAY"}
                        onClick={createOrder}
                        spinner={btnLoading || fetchTime}
                        disabled={btnLoading || fetchTime}
                        disabledTitle={"Please Wait..."}
                        style={{
                            borderRadius: "30px", fontSize: "18px",
                            width: '100%',
                            // marginLeft: '10%',
                            marginTop: '10px',
                            marginBottom: '20px',
                            background: '#00b0ff',
                            // margin: 'auto'
                            background: fetchTime ? '#b0b0b0' : '#00b0ff',
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default OrderPayment;
