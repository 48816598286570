import React from 'react'
import { Box, Button, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material'

const ProductCard = ({ product, handleClickOpen, getFoodType, quantity, index, cartItems, productsList }) => {

    const isLastRow = Math.floor(index / 3) === Math.floor((productsList.length - 1) / 3);
    const isFirstRow = index < 3;
    const totalProductQuantity = cartItems
        .filter(item => item.product?._id === product._id)
        .reduce((total, item) => total + (item.quantity || 0), 0);
    const isOnlyOneRow = productsList.length <= 3;

    return (
        <Card
            sx={{
                maxWidth: 300,
                cursor: 'pointer',
                opacity: product?.isExhausted || product?.isDelete ? 0.8 : 1,
                backgroundColor: product?.isExhausted ? '#f0f0f0' : 'white',
                position: 'relative',
                // padding: 1,
                boxShadow: 'none',
                // borderTop: isFirstRow ? '3px dashed #e0e0e0' : 'none',
                borderBottom: isOnlyOneRow ? '2px dashed #e0e0e0' : !isLastRow ? '2px dashed #e0e0e0' : 'none',
                borderLeft: (index % 3 === 1) || (index % 3 === 2) ? '3px dashed #e0e0e0' : 'none',
                maxHeight: 350
            }}
            onClick={() => (!product?.isExhausted) && handleClickOpen(product?._id)}
        >
            {(product?.isExhausted || product?.isDelete) && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: -40,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#ff0000',
                        fontSize: '18px',
                        fontWeight: 'bold',
                    }}
                >
                    Out of Stock
                </Box>
            )}

            <Box mb={1} p={1} sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '10%',
                height: '10%',
            }}>
                {Array.isArray(getFoodType(product?.foodType)) ? (
                    getFoodType(product?.foodType).map((url, index) => (
                        <img
                            key={index}
                            src={url}
                            alt={`Food type image ${index}`}
                            width={18}
                            style={{ marginRight: 4 }}
                        />
                    ))
                ) : (
                    <img
                        src={getFoodType(product?.foodType)}
                        alt="Food type"
                        width={18}
                    />
                )}
            </Box>

            <CardMedia
                component="img"
                image={product?.displayImageUrl}
                alt="product-img"
                sx={{
                    width: 100,
                    height: 100,
                    objectFit: 'cover',
                    borderRadius: '50%',
                    margin: 'auto',
                    marginTop: '10px'
                }}
            />
            <CardContent>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: 'left',
                        color: '#333',
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        marginTop: 1
                    }}
                    gutterBottom
                >
                    {product.name}
                </Typography>

                <Typography
                    variant="h6"
                    sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        textAlign: 'left',
                        color: '#333',
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        marginTop: 1
                    }}
                    gutterBottom
                >
                    {product?.category?.name}
                </Typography>


                <Box>
                    <Stack direction="row" alignItems="center" justifyContent={'flex-start'} spacing={0.5}>
                        {
                            product.offerPrice === product.mrp ? <Typography
                                variant="body1"
                                textAlign={'left'}
                                sx={{
                                    fontWeight: "bold",
                                    color: "#03a9f5",
                                    fontSize: 13,
                                    textAlign: 'left'
                                }}
                            >
                                ₹{product.offerPrice}
                            </Typography> : (
                                <>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            color: "#03a9f5",
                                            fontSize: 13,
                                        }}
                                    >
                                        ₹{product.offerPrice}
                                    </Typography>
                                    <Typography variant="body2" pt={0.4} sx={{ color: "#bcbcbd", textDecoration: "line-through", fontSize: 12, }}>
                                        ₹{Math.floor(product.mrp)}
                                    </Typography>
                                </>
                            )
                        }
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 600,
                                backgroundColor: '#e0f7fa',
                                color: '#03a9f5',
                                padding: '2px 6px',
                                fontSize: 9,
                                borderRadius: 1,
                                border: '1px solid #03a9f5',
                            }}
                        >
                            {Math.ceil(product?.offerPercentage ?? 0)}% OFF
                        </Typography>
                    </Stack>
                </Box>

                {/* Add Button */}
                <Stack direction="row" justifyContent={product?.weight && product?.weightUnit ? 'space-between' : 'flex-end'} alignItems="center" sx={{ marginTop: 2 }}>
                    {
                        (product?.weight || product?.weight === 0) && product?.weightUnit &&
                        <Typography sx={{
                            fontSize: 9,
                            textAlign: 'left',
                            color: '#464854',
                            marginTop: '5px',
                            width: '90%',
                        }}> {product?.weight} {product?.weightUnit} | {product?.calories
                            } Cal  </Typography>
                    }

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            borderRadius: 4,
                            marginTop: '2px',
                            padding: totalProductQuantity > 0 ? '4px 6px' : '4px 12px',
                            fontSize: 11,
                            backgroundColor: '#00b0ff',
                            '&:hover': {
                                backgroundColor: '#008cba'
                            }
                        }}
                    >
                        {
                            totalProductQuantity > 0 ? `${totalProductQuantity} ADDED` : " ADD"
                        }

                    </Button>
                </Stack>
            </CardContent>
        </Card >)

}

export default ProductCard;