import React, { useEffect, useRef, useState } from "react";
import {
    AppBar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Skeleton,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import axios from "../api/axios";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import ProductCard from "../components/ProductCard";
import Cart from "../components/Cart";
import CloseIcon from "@mui/icons-material/Close";
import { Add, Remove } from "@mui/icons-material";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate, useOutletContext } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

const Products = () => {
    const navigate = useNavigate();
    const idleTimerRef = useRef(null);
    const outlet = localStorage.getItem("outlet");
    const cartId = localStorage.getItem("cartId");
    const isEventOutlet = JSON.parse(localStorage.getItem("isEventOutlet"));
    const [productInfo, setProductInfo] = useState({});
    const [totalOfferPrice, setTotalOfferPrice] = useState(0);
    const [totalMrp, setTotalMrp] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedAddons, setSelectedAddons] = useState({});
    const [makingTime, setMakingTime] = useState("");
    const [cartItems, setCartItems] = useState([]);
    const [quantity, setQuantity] = useState(productInfo?.quantity || 1);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState("ALL");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [clickedCategory, setClickedCategory] = useState(null);
    const [categoriesList, setCategoriesList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [inputText, setInputText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [qtyChangingIds, setQtyChangingIds] = useState([]);
    const [isFetchData, setIsFetchData] = useState(false);
    const [isTabChange, setIsTabChange] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingIds, setDeletingIds] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    const categoryRefs = useRef([]);

    const navigateToHome = () => {
        navigate('/user-details')
    }

    const handleLogout = () => {
        if (cartId) deleteCart(cartId);
        localStorage.removeItem('cart')
        localStorage.removeItem("cartItems");
        localStorage.removeItem("kioskUser");
        localStorage.removeItem("orderAmount");
        localStorage.removeItem("orderId");
        localStorage.removeItem("cartId");
        localStorage.removeItem("additionalCharges");
        localStorage.removeItem("cartReadyTime");
        localStorage.removeItem("rzp_checkout_anon_id")
        localStorage.removeItem("rzp_device_id")
        navigateToHome()
    }

    const handleCategoryClick = (categoryId, index) => {
        // categoryRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
        setActiveCategory(categoryId); // Update active category immediately
        filtereByFoodTypeAndSearch(selectedTab, inputText, categoryId);

        setClickedCategory((prevClickedCategory) => {
            if (prevClickedCategory === categoryId) {
                // Reset if the same category is clicked again
                setSelectedCategory(null);
                // setSelectedTab("ALL");
                // setInputText("")
                filtereByFoodTypeAndSearch(selectedTab, inputText, null);
                // getCategoriesWithProducts(); // Fetch all products again
                return null;
            } else {
                // When a new category is clicked, set the category and fetch relevant products
                // const selectedCategory = categoriesList.find(
                //     (item) => item?._id === categoryId
                // );
                console.log("selectedCategory", selectedCategory);
                // if (selectedCategory) {
                //     setSelectedCategory(categoryId);
                //     // setSelectedTab("ALL");
                //     // setInputText("");
                //     filtereByFoodTypeAndSearch(selectedTab, inputText, categoryId); 
                //     return categoryId;
                // }
                // return prevClickedCategory;
            }
        });
    };

    const toggleDrawer = (open) => () => {
        setIsDrawerOpen(open);
        setQuantity(1);
        setIsOpen(false);
    };

    const handleClickOpen = (productId) => {
        const product = categoriesList
            .find((cat) => cat.products.some((item) => item._id === productId))
            ?.products.find((item) => item._id === productId)

        if (!product) return;

        setProductInfo(product);

        // Initialize selectedAddons with default addons
        const initialAddons = {};
        let initialTotalPrice = product.offerPrice || 0;
        let initialTotalMrp = product.mrp || 0;

        product.addons?.forEach((addon) => {
            addon.addonValues?.forEach((item) => {
                if (item.isDefault) {
                    initialAddons[addon._id] = {
                        _id: addon._id,
                        addonValueId: item._id,
                        price: item.offerPrice || 0,
                        selectedValue: item.value,
                        mrp: item?.mrp ? item?.mrp : 0,
                    };
                    initialTotalPrice += item.offerPrice || 0;
                    initialTotalMrp += item?.mrp != null ? item?.mrp : 0;
                }
            });
        });

        setSelectedAddons(initialAddons);
        setTotalOfferPrice(initialTotalPrice);
        setTotalMrp(initialTotalMrp);
        toggleDrawer(true)();
    };

    const getFoodType = (foodType) => {
        switch (foodType) {
            case "VEG":
                return "/assets/VegImagee.png";
            case "NON-VEG":
                return "/assets/NonVeg.png";
            case "VEGAN":
                return "/assets/Vegan.png";
            case "VEG/VEGAN":
                return ["/assets/VegImagee.png", "/assets/Vegan.png"];
            default:
                return "https://example.com/images/unknown.png";
        }
    };

    const handleAddonClick = (
        addonId,
        addonValueId,
        addonPrice = 0,
        addonName,
        addonMrp = 0
    ) => {
        setSelectedAddons((prevSelectedAddons) => {
            const newSelectedAddons = { ...prevSelectedAddons };
            const isCurrentlySelected = newSelectedAddons[addonId]?.addonValueId === addonValueId;

            // Check if the addon has a default value
            const hasDefault = productInfo.addons.find((addon) => addon._id === addonId)?.addonValues.some(value => value.isDefault);

            if (!isCurrentlySelected) {
                // Remove the previous selection for this addon if any
                if (newSelectedAddons[addonId]) {
                    const currentPrice = newSelectedAddons[addonId].price || 0;
                    const currentMrp = newSelectedAddons[addonId].mrp || 0;

                    setTotalOfferPrice((prevTotalPrice) => prevTotalPrice - currentPrice);
                    setTotalMrp((prevTotalMrp) => prevTotalMrp - currentMrp);
                }

                // Set new selection
                newSelectedAddons[addonId] = {
                    _id: addonId,
                    addonValueId: addonValueId,
                    price: addonPrice,
                    selectedValue: addonName,
                    mrp: addonMrp || 0,
                };

                setTotalOfferPrice((prevTotalPrice) => prevTotalPrice + (addonPrice || 0));
                setTotalMrp((prevTotalMrp) => prevTotalMrp + (addonMrp || 0));
            } else if (!hasDefault) {
                // If there's no default value, allow deselection
                const currentPrice = newSelectedAddons[addonId].price || 0;
                const currentMrp = newSelectedAddons[addonId].mrp || 0;

                delete newSelectedAddons[addonId];

                setTotalOfferPrice((prevTotalPrice) => prevTotalPrice - currentPrice);
                setTotalMrp((prevTotalMrp) => prevTotalMrp - currentMrp);
            }

            return newSelectedAddons;
        });
    };

    const preparePayloadWithAddons = (
        productData,
        selectedAddons = {},
        newQuantity
    ) => {
        const productId = productData._id;
        let quantity = Number(productData.quantity) || 0;

        newQuantity = Number(newQuantity) || 0;

        // Add the newQuantity to the existing quantity
        quantity += newQuantity;

        let addons = [];
        if (selectedAddons && Object.keys(selectedAddons).length > 0) {
            addons = Object.values(selectedAddons).map((addon) => ({
                _id: addon._id,
                selectedValue: addon.selectedValue,
                addonValueId: addon.addonValueId,
                price: addon.price,
            }));
        }

        // Build payload with conditionally included addons
        const cartItem = {
            product: productId,
            quantity: quantity,
        };

        // Conditionally add addons to cartItem if they are present
        if (addons.length > 0) {
            cartItem.addons = addons;
        }

        const payload = {
            ordersType: "PICKUP",
            outlet: outlet,
            cartItems: [cartItem],
        };

        return payload;
    };

    const preparePayloadWithUpdatedQuantity = (
        productData,
        selectedAddons,
        updatedQuantity
    ) => {
        const productId = productData._id;
        const quantity = Number(updatedQuantity) || 0;

        let addons = [];
        if (selectedAddons && Object.keys(selectedAddons).length > 0) {
            addons = Object.values(selectedAddons).map((addon) => ({
                _id: addon._id,
                selectedValue: addon.selectedValue,
                addonValueId: addon.addonValueId,
                price: addon.price,
                mrp: addon.mrp, // Include mrp field here
            }));
        }

        const cartItem = {
            product: productId,
            quantity: quantity,
        };

        if (addons.length > 0) {
            cartItem.addons = addons;
        }

        const payload = {
            ordersType: "PICKUP",
            outlet: outlet,
            cartItems: [cartItem],
        };

        setIsOpen(true);
        return payload;
    };

    const handleAddProductToCartWithAddons = async (productData) => {
        setIsLoading(true)
        try {
            const token = localStorage.getItem("authToken");

            const product = categoriesList.find((cat) => cat.products.some((item) => item._id === productData._id))?.products.find((item) => item._id === productData?._id)

            if (!product) {
                return toast.error("No product found.", {
                    position: "top-center",
                    autoClose: 2000,
                    theme: "colored",
                    type: "info"
                });
            }
            product.quantity = 1;

            let payload;
            if (isOpen) {
                payload = preparePayloadWithUpdatedQuantity(
                    productInfo,
                    selectedAddons,
                    quantity
                );
            } else {
                payload = preparePayloadWithAddons(product, selectedAddons);
            }

            // Make API call to add product to cart
            const response = await axios.post("cart", payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.status === 200) {
                const message = response?.data?.data?.message;
                const cartId = response?.data?.data?.cart?._id;
                localStorage.setItem("cartId", cartId);

                toast.success(message, {
                    position: "top-center",
                    autoClose: 2000,
                    theme: "colored",
                    type: "info"
                });
                setIsDrawerOpen(false);
                getCartItems();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
        finally {
            setIsLoading(false)
        }
    };

    const getCartItems = async () => {
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.get(`cart/userCart/${outlet}/PICKUP`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.data?.status === true) {
                const cartItems = response?.data?.data?.cartItems;

                let items = cartItems.filter(
                    (item) => item?.product?.isDelete === false
                );

                const readyTime = items?.reduce((acc, item) => {
                    const makingTime = Number(item?.product?.makingTime);
                    return acc + (isNaN(makingTime) ? 0 : makingTime);
                }, 0);

                setMakingTime(readyTime)
                setCartItems(items);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const deleteCartItem = async (id) => {
        setIsDeleting(true)
        setDeletingIds((prevIds) => [...prevIds, id]);
        try {
            const token = localStorage.getItem("authToken");

            const payload = {
                cartItemIds: [id],
            };

            const response = await axios.delete("cart", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: payload,
            });

            if (response?.status === 200) {
                setCartItems((prevItems) =>
                    prevItems.filter((item) => item._id !== id)
                );
                const message = response?.data?.data?.message;
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsDeleting(false)
            setDeletingIds((prevIds) => prevIds.filter((itemId) => itemId !== id));
        }
    };

    const handleQuantityChange = async (productId, newQuantity) => {
        const previousCartItems = [...cartItems];

        setCartItems((prevItems) =>
            prevItems.map((item) =>
                item._id === productId ? { ...item, quantity: newQuantity } : item
            )
        )
        setQtyChangingIds((prevIds) => [...prevIds, productId]);

        try {
            if (newQuantity === 0) {
                deleteCartItem(productId);
                return;
            }

            const token = localStorage.getItem("authToken");

            const data = {
                cartItems: [
                    {
                        _id: productId,
                        quantity: newQuantity
                    }

                ]
            }

            const response = await axios.put("cart", data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.status === 200) {
                getCartItems();
            }
        } catch (error) {
            setCartItems(previousCartItems);
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setQtyChangingIds((prevIds) => prevIds.filter((id) => id !== productId));
        }
    };

    const handleInputChange = (e) => {
        const searchText = e.target.value;
        setInputText(searchText);
        filtereByFoodTypeAndSearch(selectedTab, searchText);
    };

    const handleChangeQuantity = (change) => {
        const updatedQuantity = quantity + change;

        // Prevent quantity from being 0 or less
        if (updatedQuantity < 1) {
            return;
        }

        handleProdQtyChange(change, productInfo, setProductInfo);
        setQuantity(updatedQuantity);
        // Prepare payload with updated quantity
        const payload = preparePayloadWithUpdatedQuantity(
            productInfo,
            selectedAddons,
            updatedQuantity
        );
        // Use the payload as needed
    };

    const handleProdQtyChange = (change, productInfo, setProductInfo) => {
        setProductInfo((prevInfo) => ({
            ...prevInfo,
            quantity: Math.max(prevInfo.quantity + change, 0),
        }));
    };

    const getCategoriesWithProducts = async () => {
        setIsFetchData(true)
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.get(
                `masters/category/getCategoryWithProducts/${outlet}`,
                // {
                //     headers: {
                //         Authorization: `Bearer ${token}`,
                //     },
                // }
            );

            if (response?.status === 200) {
                const categories = response?.data?.data?.categories;
                let filteredCategories;
                let products
                let filteredProducts

                if (isEventOutlet) {
                    filteredCategories = categories?.filter((item) => item?.isEventCategory === true)
                } else {
                    filteredCategories = categories?.filter((item) => item?.isEventCategory === false)
                }

                setCategoriesList(filteredCategories);

                // products = filteredCategories.map((cat) => cat.products).flat();

                // if (isEventOutlet) {
                //     filteredProducts = products?.filter((item) => item?.isEventProduct === true)
                // } else {
                //     filteredProducts = products?.filter((item) => item?.isEventProduct === false)
                // }
                // setProductsList(filteredProducts);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsFetchData(false)
        }
    };

    const filtereByFoodTypeAndSearch = async (foodType, text) => {
        setIsTabChange(true);
        try {
            let url = `masters/category/getCategoryWithProducts/${outlet}`;
            const params = [];

            // Only add parameters when foodType is not "ALL"
            if (foodType && foodType !== "ALL") params.push(`foodType=${foodType}`);
            if (text) params.push(`search=${text}`);
            // if (categoryId) params.push(`categoryId=${categoryId}`);

            // Append query parameters to the URL if any
            if (params.length > 0) url += `?${params.join("&")}`;

            const response = await axios.get(url);

            if (response?.status === 200) {
                const categories = response?.data?.data?.categories;

                const filteredCategories = isEventOutlet
                    ? categories.filter((item) => item?.isEventCategory === true)
                    : categories.filter((item) => item?.isEventCategory === false);

                setCategoriesList(filteredCategories);

                // Optional: Uncomment this block if product processing is needed
                // const products = filteredCategories
                //     .map((cat) => (categoryId ? cat.products.filter((p) => p.categoryId === categoryId) : cat.products))
                //     .flat()
                //     .filter((item) =>
                //         isEventOutlet ? item.isEventProduct === true : item.isEventProduct === false
                //     );

                // setProductsList(products);
            }
        } catch (error) {
            // Handle errors with specific messages
            if (error?.response) {
                let errorMessage = "";
                switch (error.response?.status) {
                    case 406:
                        errorMessage = error.response?.data?.error?.message || error.response?.data?.error;
                        break;
                    case 412:
                        errorMessage = error.response?.data?.data?.message;
                        break;
                    case 500:
                        errorMessage = error.response?.data?.error;
                        break;
                    default:
                        errorMessage = "An unexpected error occurred.";
                }
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsTabChange(false);
        }
    };

    const deleteCart = async (id) => {
        try {
            const token = localStorage.getItem("authToken");

            const payload = {
                cartId: id,
            };

            const response = await axios.delete("cart", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: payload,
            });

            if (response?.status === 200) {
                const message = response?.data?.data?.message;
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        filtereByFoodTypeAndSearch(newValue, inputText);
    };

    const handleSidebarClick = (categoryId, index) => {
        categoryRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
        setActiveCategory(categoryId);
    };

    // Calculate the total rate
    const totalRate = cartItems.reduce((acc, product) => {
        const baseRate = (product?.rate || 0) * (product?.quantity || 1);
        const addonsRate = product?.addons?.reduce((addonAcc, addon) => {
            return addonAcc + (addon?.offerPrice || 0);
        }, 0) * (product?.quantity || 1);
        return acc + baseRate + addonsRate;
    }, 0)

    const navigateToOrderPayment = () => {
        navigate("/order-payment");
    };

    // const resetIdleTimer = () => {
    //     if (idleTimerRef.current) clearTimeout(idleTimerRef.current);

    //     idleTimerRef.current = setTimeout(() => {
    //         handleLogout();
    //         // Refresh the page
    //         window.location.reload();
    //     }, 60000); // 1 minute
    // };

    useEffect(() => {
        if (!productInfo) return;

        const basePrice = productInfo.offerPrice || 0;
        const addonsPrice = Object.values(selectedAddons).reduce(
            (total, addon) => total + addon.price,
            0
        );
        productInfo.quantity = 1;
        setTotalOfferPrice(basePrice + addonsPrice);
    }, [selectedAddons, productInfo]);

    useEffect(() => {
        if (cartId) getCartItems();
        setSelectedTab("ALL");
        setSelectedCategory(null);
        setInputText("")

        // Get categories if no search input or if all tab is selected
        if (inputText === "" && selectedTab === "ALL") {
            getCategoriesWithProducts();
        } else {
            filtereByFoodTypeAndSearch(selectedTab, inputText)
        }

    }, []);

    // useEffect(() => {
    //     const handleUserActivity = () => resetIdleTimer();
    //     window.addEventListener("mousemove", handleUserActivity);
    //     window.addEventListener("keydown", handleUserActivity);
    //     window.addEventListener("scroll", handleUserActivity);
    //     window.addEventListener("touchstart", handleUserActivity);

    //     return () => {
    //         window.removeEventListener("mousemove", handleUserActivity);
    //         window.removeEventListener("keydown", handleUserActivity);
    //         window.addEventListener("scroll", handleUserActivity);
    //         window.addEventListener("touchstart", handleUserActivity);
    //     };
    // }, []);

    {
        isFetchData && <Box sx={{ width: '50%', margin: 'auto', marginTop: '600px', height: "calc(100vh - 240px)" }}>
            <CircularProgress size={24} />
        </Box>
    }

    return (
        <Box
            sx={{
                position: 'relative',
                background: 'white',
            }}
        >
            <AppBar position="fixed" color="inherit" elevation={0}>
                <Toolbar sx={{ padding: '10px', background: '#dfeef5' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src="/assets/brand-logo.png"
                            alt="Brand Logo"
                            style={{ height: 50, marginRight: 5 }}
                        />
                    </Box>

                    <Box sx={{ flexGrow: 2, display: 'flex', justifyContent: 'center' }}>
                        <FormControl sx={{ width: '70%' }}>
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon sx={{ color: '#00b0ff' }} />
                                    </InputAdornment>
                                }
                                onChange={handleInputChange}
                                placeholder="Search for the perfect brew"
                                sx={{ background: 'white', borderRadius: '8px' }}
                            />
                        </FormControl>
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Button
                            variant="contained"
                            onClick={handleLogout}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '8px 12px',
                                backgroundColor: '#fff',
                                color: '#000',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}
                        >
                            <LogoutIcon sx={{ mb: 0.5, color: '#000' }} />
                            Close
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Filters Section */}
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={2}
                mt={10}
                p={2}
            // sx={{ bgcolor: 'white', borderRadius: '8px' }}
            >
                <Typography variant="body1" fontWeight={'medium'} color="textSecondary">
                    Sort By
                </Typography>

                <Box
                    sx={{
                        backgroundColor: '#e3f2fd',
                        borderRadius: 4,
                        padding: '5px',
                        display: 'flex',
                    }}
                >
                    {['ALL', 'VEGAN', 'VEG', 'NON-VEG'].map((tab) => (
                        <Button
                            key={tab}
                            onClick={(event) => handleTabChange(event, tab)}
                            sx={{
                                color: selectedTab === tab ? '#fff' : '#636363',
                                backgroundColor: selectedTab === tab ? '#03a9f5' : 'transparent',
                                '&:hover': {
                                    backgroundColor: selectedTab === tab ? '#039be5' : '#f1f1f1',
                                    color: selectedTab === tab ? 'white' : '#03a9f5',
                                },
                                borderRadius: '20px',
                                padding: '5px 15px',
                                fontWeight: 'bold',
                                textTransform: 'capitalize',
                                marginRight: '10px',
                            }}
                        >
                            {['VEGAN', 'VEG', 'NON-VEG'].includes(tab) && (
                                <img
                                    src={getFoodType(tab)}
                                    alt={tab}
                                    style={{
                                        width: '15px',
                                        height: '15px',
                                        marginRight: '5px',
                                    }}
                                />
                            )} {tab}
                        </Button>
                    ))}
                </Box>
            </Box>

            {/* Main Content */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    maxHeight: 'calc(100vh - 160px)',
                    gap: 2
                }}
            >
                {/* Sidebar */}
                <Box
                    pl={2}
                    pt={2}
                    sx={{
                        width: '20%',
                        // height: '100vh',
                        maxHeight: 'calc(100vh - 100px)',
                        bgcolor: '#DFEEF4',
                        borderTopRightRadius: '116px',
                        overflowY: 'auto',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    <Stack>
                        {isFetchData || isTabChange ? (
                            <Stack>
                                {Array.from(new Array(14)).map((_, index) => (
                                    <Box sx={{ padding: 2 }}>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            {/* Rounded Skeleton */}
                                            <Skeleton variant="circular" width={55} height={55} />
                                            {/* Text Skeleton */}
                                            <Skeleton variant="text" width="60%" height={30} />
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>)
                            : (categoriesList.map((item, index) => (
                                <Stack key={item._id} direction="row" alignItems="center" gap={1} mt={2}
                                    onClick={() => handleSidebarClick(item._id, index)}
                                    sx={{
                                        cursor: "pointer",
                                        backgroundColor:
                                            activeCategory === item._id ? "#03a9f5" : "transparent",
                                        color: activeCategory === item._id ? "#fff" : "#000",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <Box sx={{ borderRadius: '50%', width: 55, height: 55 }}>
                                        <img
                                            src={item.imageURL}
                                            alt="category"
                                            style={{ width: '100%', height: '100%' }}
                                        />
                                    </Box>
                                    <Typography fontSize={14} mt={1} ml={1} textAlign="left">
                                        {item?.name}
                                    </Typography>
                                </Stack>
                            )))}
                    </Stack>
                </Box>

                {/* Products Section */}
                <Box
                    className="products-section"
                    sx={{
                        width: '78%',
                        // bgcolor: '#DFEEF4',
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 100px)',
                        // borderBottomLeftRadius: '100px'
                    }}
                >
                    {isFetchData || isTabChange ? (
                        <Grid container spacing={2}>
                             <Skeleton variant="rectangular" height={100} width="93%" sx={{ borderRadius: '8px', marginBottom: 1, marginLeft: 4 }} />
                            {Array.from(new Array(30)).map((_, index) => (
                                <Grid key={index} item xs={12} sm={4} md={4}>
                                    <Box sx={{ padding: 2 }}>
                                        <Skeleton variant="rectangular" height={200} sx={{ borderRadius: '8px', marginBottom: 1 }} />
                                        <Skeleton variant="text" width="80%" />
                                        <Skeleton variant="text" width="60%" />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        categoriesList.map((category, index) => (
                            <Box
                                key={category._id}
                                ref={(el) => (categoryRefs.current[index] = el)}
                                data-category-id={category._id}
                                sx={{ marginBottom: 2, borderTopRightRadius: '30px', borderTopLeftRadius: '30px' }}
                            >
                                <img
                                    src={category.bannerImageUrl}
                                    alt="category banner"
                                    style={{ width: '100%', borderTopRightRadius: '30px', borderTopLeftRadius: '30px' }}
                                />

                                <Grid container >
                                    {category.products && category.products.length > 0 ? (
                                        category.products.map((product, index) => (
                                            <Grid key={product._id} item xs={12} sm={4} md={4}>
                                                <ProductCard
                                                    product={product}
                                                    handleClickOpen={handleClickOpen}
                                                    getFoodType={getFoodType}
                                                    quantity={quantity}
                                                    index={index}
                                                    cartItems={cartItems}
                                                    productsList={productsList}
                                                />
                                            </Grid>
                                        ))
                                    ) : (
                                        <Typography
                                            sx={{ textAlign: 'center', width: '100%' }}
                                            variant="h6"
                                        >
                                            No Products Found
                                        </Typography>
                                    )}
                                </Grid>
                            </Box>
                        ))
                    )}
                </Box>
            </Box>

            {/* Fixed Bottom bar */}
            <Box
                sx={{
                    borderRadius: '30px',
                    width: '50%',
                    height: '50px',
                    position: 'fixed',
                    bottom: 100,
                    left: '25%',
                    zIndex: 100,
                    backgroundColor: '#00b0ff',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center'
                }}
                onClick={navigateToOrderPayment}
            >
                <Box>
                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ color: 'white', fontWeight: 'bold', }} gap={1}>
                        <Box><ShoppingCartOutlinedIcon /></Box>
                        <Box>{cartItems.length} Items</Box>
                    </Stack>

                </Box>
                <Box sx={{ fontWeight: 'bold', color: 'white' }}>
                    Proceed | ₹ {totalRate?.toFixed(2)}
                </Box>
            </Box>

            {/* DRAWER  */}
            <Box sx={{ overflow: "hidden" }}>
                <Drawer
                    anchor="bottom"
                    open={isDrawerOpen}
                    onClose={toggleDrawer(false)}
                    transitionDuration={500}
                    PaperProps={{
                        sx: {
                            maxWidth: "500px",
                            margin: "auto",
                            borderTopLeftRadius: "16px",
                            borderTopRightRadius: "16px",
                            padding: 3,
                            boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                            display: "flex",
                            flexDirection: "column",
                        },
                    }}
                >
                    {/* Fixed Top Section */}
                    <Box>
                        <Stack direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                            {/* Product Image */}
                            <Box sx={{ width: "100px", height: "100px", flexShrink: 0 }}>
                                <img
                                    src={productInfo?.displayImageUrl}
                                    alt="product-image"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                    }}
                                />
                            </Box>

                            {/* Product Details */}
                            <Box>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width={"100%"}
                                >
                                    <Box>
                                        <IconButton
                                            onClick={toggleDrawer(false)}
                                            sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 12,
                                                color: "#222124",
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Stack>
                                <Typography
                                    variant="h6"
                                    sx={{ fontWeight: "bold", lineHeight: 1, fontSize: '17px' }}
                                >
                                    {productInfo?.name}
                                </Typography>
                                <Typography mt={1} variant="body2" sx={{ fontWeight: 'bold', color: "text.secondary", fontSize: '12px' }}>
                                    {productInfo?.description}
                                </Typography>
                                <Stack direction={'row'} alignItems={'center'} mt={2} gap={1}>
                                    <Box mb={1} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        {Array.isArray(getFoodType(productInfo?.foodType)) ? (
                                            getFoodType(productInfo?.foodType).map((url, index) => (
                                                <img
                                                    key={index}
                                                    src={url}
                                                    alt={`Food type image ${index}`}
                                                    width={18}
                                                    style={{ marginRight: 4 }}
                                                />
                                            ))
                                        ) : (
                                            <img
                                                src={getFoodType(productInfo?.foodType)}
                                                alt="Food type"
                                                width={18}
                                            />
                                        )}
                                    </Box>
                                    {
                                        (productInfo?.weight != null && productInfo?.weightUnit) && (
                                            <Box sx={{ marginTop: '-5px' }}>
                                                <Typography sx={{ fontWeight: 'bold', color: "text.secondary" }}>
                                                    {productInfo.weight} {productInfo.weightUnit}
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                    {
                                        productInfo?.calories != null && (
                                            <Box sx={{ marginTop: '-5px' }}>
                                                <Typography sx={{ fontWeight: 'bold', color: "text.secondary" }}>
                                                    | {productInfo.calories} Cal
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                    {/* Scrollable Content */}
                    {productInfo?.addons && productInfo.addons.length > 0 && (
                        <Box sx={{
                            flex: 1, overflowY: "auto", background: '#f0f4f5', padding: '10px', height: '400px', maxHeight: '400px', position: 'relative',
                            "&::-webkit-scrollbar": {
                                width: "6px",
                                height: "8px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#00b0ff",
                                borderRadius: "50px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#00b0ff",
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#c6c8ca",
                            },
                        }}>
                            {/* Addons Section */}
                            <Box >
                                {productInfo.addons.map((addon, index) => (
                                    <Box key={addon._id} sx={{ marginBottom: 1, background: 'white', padding: '10px', borderRadius: '30px' }}>
                                        <Stack direction="row" spacing={1} justifyContent={'space-between'}>
                                            <Box>
                                                <Typography
                                                    variant="h6"
                                                    sx={{ fontWeight: "bold", width: "15%", fontSize: '15px', marginRight: '10px', marginTop: '10px' }}
                                                >
                                                    {addon.addonTitle}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Stack direction={addon?.addonTitle === "Size" ? 'row' : 'column'} spacing={2} flexWrap="wrap" sx={{ marginRight: '20px' }}>
                                                    {addon.addonValues.map((item) => {
                                                        const isSizeAddon = addon.addonTitle === 'Size';
                                                        const isSelected = selectedAddons[addon._id]?.addonValueId === item._id;

                                                        let imgSrc = `/assets/${item.value}-${isSelected ? 'full' : 'empty'}.jpeg`;
                                                        let imgStyle = { width: '50px', height: '50px' };
                                                        if (item.value === "Medium") {
                                                            imgSrc = `/assets/medium-${isSelected ? 'full' : 'empty'}.jpeg`;
                                                            imgStyle = { width: '26px', height: '45px' };
                                                        } else if (item.value === "Large") {
                                                            imgSrc = `/assets/large-${isSelected ? 'full' : 'empty'}.jpeg`;
                                                            imgStyle = { width: '25px', height: '55px' };
                                                        } else if (item.value === "Small" || item.value === "Regular") {
                                                            imgSrc = `/assets/small-${isSelected ? 'full' : 'empty'}.jpeg`;
                                                            imgStyle = { width: '20px', height: '35px' };
                                                        }

                                                        return (
                                                            <Stack key={item._id} direction="column" alignItems="center" justifyContent={'flex-end'}>
                                                                {isSizeAddon ? (
                                                                    <>
                                                                        <Box>
                                                                            <Typography
                                                                                variant="caption"
                                                                                sx={{
                                                                                    color: "#000",
                                                                                    fontWeight: "bold",
                                                                                    textAlign: "center",
                                                                                    marginBottom: '5px',
                                                                                }}
                                                                            >
                                                                                {item?.subLabel}
                                                                            </Typography>
                                                                        </Box>


                                                                        {/* Image */}
                                                                        <Box>
                                                                            <img
                                                                                onClick={() =>
                                                                                    handleAddonClick(
                                                                                        addon._id,
                                                                                        item._id,
                                                                                        item.offerPrice || 0,
                                                                                        item.value,
                                                                                        item?.mrp !== undefined && item?.mrp !== null ? item.mrp : 0
                                                                                    )
                                                                                }
                                                                                src={imgSrc}
                                                                                alt={item.value}
                                                                                style={imgStyle}
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <Typography
                                                                                variant="caption"
                                                                                sx={{ color: "success.main", fontWeight: "bold" }}
                                                                            >
                                                                                <span style={{ color: isSelected ? "green" : "inherit", marginLeft: '5px' }}>
                                                                                    {item.offerPrice ? `+₹${item.offerPrice}` : <span style={{ color: "green" }}>FREE</span>}
                                                                                </span>
                                                                            </Typography>
                                                                        </Box>
                                                                    </>
                                                                ) : (
                                                                    <Button
                                                                        variant={isSelected ? "contained" : "outlined"}
                                                                        onClick={() =>
                                                                            handleAddonClick(
                                                                                addon._id,
                                                                                item._id,
                                                                                item.offerPrice || 0,
                                                                                item.value,
                                                                                item?.mrp !== undefined && item?.mrp !== null ? item.mrp : 0
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            textTransform: "none",
                                                                            margin: "4px",
                                                                            fontSize: "0.81rem",
                                                                            borderRadius: "20px",
                                                                            padding: "1px 16px",
                                                                            backgroundColor: isSelected ? "#00b0ff" : "transparent",
                                                                            color: isSelected ? "#fff" : "inherit",
                                                                            borderColor: isSelected ? "transparent" : "#00b0ff",
                                                                            "&:hover": {
                                                                                backgroundColor: isSelected ? "#00b0ff" : "#bfbfbf",
                                                                                borderColor: isSelected ? "transparent" : "#bfbfbf",
                                                                            },
                                                                        }}
                                                                    >
                                                                        {item.value}  <span style={{ color: isSelected ? "green" : "inherit", marginLeft: '5px' }}>
                                                                            {item.offerPrice ? `+₹${item.offerPrice}` : <span style={{ color: "green" }}>FREE</span>}
                                                                        </span>
                                                                    </Button>
                                                                )}
                                                            </Stack>
                                                        );
                                                    })}
                                                </Stack>
                                            </Box>
                                        </Stack>
                                        {index < productInfo.addons.length - 1 && <Divider sx={{ marginY: 2 }} />}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    )
                    }

                    {/* Price and Actions */}
                    <Box sx={{ paddingTop: 2, borderTop: "1px solid #ddd" }}>
                        <Stack direction="row" alignItems="center" justifyContent={'space-between'} mb={2}>
                            <Box sx={{ width: '30%' }}>
                                <Stack direction={"row"} spacing={1}>
                                    <Remove
                                        onClick={() => handleChangeQuantity(-1)}
                                        className="cursor-p"
                                        style={{
                                            fontSize: "2rem",
                                            color: "#fff",
                                            backgroundColor: "#00b0ff",
                                            borderRadius: "50px",
                                        }}
                                    />
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        sx={{
                                            width: "30px",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography sx={{ fontSize: 22 }}>{quantity}</Typography>
                                    </Stack>
                                    <Add
                                        onClick={() => handleChangeQuantity(1)}
                                        className="cursor-p"
                                        style={{
                                            fontSize: "2rem",
                                            color: "#fff",
                                            backgroundColor: "#00b0ff",
                                            borderRadius: "50px",
                                        }}
                                    />
                                </Stack>
                            </Box>


                            <Box sx={{ width: '60%' }}>
                                <PrimaryButton
                                    title={`Add Item | ₹ ${quantity * totalOfferPrice?.toFixed(2)}`}
                                    onClick={() => handleAddProductToCartWithAddons(productInfo)}
                                    disabled={isLoading}
                                    spinner={isLoading}
                                    disabledTitle={"Please Wait..."}
                                    style={{
                                        borderRadius: "30px", fontSize: "18px",
                                        width: '100%',
                                        background: '#00b0ff',
                                    }}
                                />
                            </Box>
                        </Stack>

                    </Box>
                </Drawer>
            </Box>
        </Box>
    );
};

export function CategoryFilterChip({
    filterText,
    isSelected,
    onClick,
    clickable = true,
}) {
    return (
        <Chip
            label={filterText}
            onClick={clickable ? onClick : undefined}
            variant={isSelected ? "default" : "outlined"}
            className={isSelected ? "clickedCategoryChip" : "unclickedCategoryChip"}
            clickable={clickable}
            sx={{
                backgroundColor: isSelected ? "#00b0ff" : "white",
                borderColor: isSelected ? "#00b0ff" : "default",
                color: isSelected ? "white" : "default",
            }}
        />
    );
}

export default Products;
